import data from "../Assets/Data/data.js";
import Subscribe from "../Components/Subscribe";
import SocialBar from "../Components/SocialBar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import PropertyCards from "../Components/PropertyCards";
import Header from "../Components/Header";
import SEO from "../Components/SEO";
import ProjectsBackground6 from "../Assets/Images/BackgroundImages/ProjectsBackground-6_Compressed.webp";
import {
	ImageListItem,
	useTheme,
	ImageList,
	Box,
	Typography,
	useMediaQuery,
	Pagination,
} from "@mui/material";

const ScrollToTop = ({ currentPage }) => {
	useEffect(() => {
		window.scrollTo({ top: "340", left: "0", behavior: "smooth" });
	}, [currentPage]);

	return null;
};

const Projects = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Pagination State
	const [currentPage, setCurrentPage] = useState(1);
	const projectsPerPage = 10;

	// Calculate Total Pages
	const totalPages = Math.ceil(data.length / projectsPerPage);

	// Get Curren Projects
	const indexOfLastProject = currentPage * projectsPerPage;
	const indexOfFirstProject = indexOfLastProject - projectsPerPage;
	const currentProjects = data.slice(indexOfFirstProject, indexOfLastProject);

	// Handler to update the page change
	const handlePageChange = (event, value) => {
		setCurrentPage(value);
	};

	const activeListings = currentProjects.map((project) => {
		return (
			<Link to={`/active-projects/${project.id}`} key={project.id}>
				<ImageListItem>
					<PropertyCards
						imageUrl={project.coverImage}
						alt={project.coverImageAlt}
						title={project.title}
						developer={project.developer}
						price={project.price}
					/>
				</ImageListItem>
			</Link>
		);
	});
	return (
		<>
			<SEO
				title={"Annkastles | Active Projects"}
				description={
					"Discover unparalleled luxury properties for sale in Dubai with Annkastles. Enjoy a commission-free experience with personalized service. Elevate your living standards in the heart of Dubai's prestigious real estate landscape – contact us today and discover a world where luxury meets unrivaled service in every detail."
				}
			/>
			<Header backgroundImage={ProjectsBackground6} />
			<Box
				py={isMobile ? 1 : 5}
				px={isMobile ? 4 : 10}
				mb={isMobile ? 0 : 4}
				align="center"
			>
				<Typography variant="heading1" component={"h1"} pb={isMobile ? 0 : 3}>
					CRÈME DE LA CRÈME
				</Typography>
				<ImageList
					component={"ul"}
					variant="masonry"
					cols={isMobile ? 1 : 2}
					gap={isMobile ? 15 : 3}
				>
					{activeListings}
				</ImageList>
				<Box mt={6} display="flex" justifyContent="center">
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						color="secondary"
						shape="rounded"
					/>
				</Box>
			</Box>
			<ScrollToTop currentPage={currentPage} />
			<SocialBar />
			<Subscribe />
		</>
	);
};
export default Projects;
