import testimonials from "../Assets/Data/testimonials.json";
import { Card, CardContent, Typography } from "@mui/material";

const TestimonialMobile = ({ currentTestimonialIndex, mobileTestimonials }) => {
	return (
		<article>
			<Card
				sx={{
					background: "#1B1B1B",
					boxShadow: "none",
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					justifyContent: "center",
					textAlign: "justify",
				}}
			>
				<CardContent>
					<Typography variant="body4" component="blockquote">
						{mobileTestimonials[currentTestimonialIndex].description}
					</Typography>
					<Typography variant="body4" component="footer" pt={2} align="right">
						<cite title="Name of the endorser">
							{" "}
							- {mobileTestimonials[currentTestimonialIndex].person}
						</cite>
					</Typography>
				</CardContent>
			</Card>
			;
		</article>
	);
};

export default TestimonialMobile;
