import { Helmet } from "react-helmet-async";

const SEO = ({ title, description }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta property="og:type" content="article" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{/* <meta property="og:image" content={ogImage} /> */}
		</Helmet>
	);
};
export default SEO;
