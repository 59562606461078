import {
	ImageList,
	ImageListItem,
	useMediaQuery,
	useTheme,
	Box,
} from "@mui/material";
import "../Assets/Styles/Project.css";

const ImageSquare = ({ images, title }) => {
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));

	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

	return (
		<Box
			bgcolor={"white"}
			px={isDesktop ? 3.9 : isMobile ? 0 : 2}
			mt={isMobile || isMedium ? 0 : 3}
		>
			<ImageList variant="standard" cols={2} gap={12}>
				{images.slice(0, 4).map((image, index) => {
					const imageName = decodeURIComponent(
						image.substring(image.lastIndexOf("/") + 1, image.lastIndexOf("."))
					);
					const lastPart = imageName.substring(imageName.lastIndexOf("/") + 1);

					return (
						<ImageListItem key={index} className="image-list-item">
							<img
								src={image}
								alt={lastPart}
								style={{
									maxWidth: isMobile || isMedium ? "44dvw" : "18dvw",
									height: isMobile || isMedium ? "18dvh" : "24dvh",
									objectFit: "cover",
								}}
							/>
						</ImageListItem>
					);
				})}
			</ImageList>
		</Box>
	);
};

export default ImageSquare;
