import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import logo from "../Assets/Images/logo.png";
import { minHeight } from "@mui/system";
import { useMatch } from "react-router-dom";
const Footer = () => {
	const theme = useTheme();
	return (
		<section style={theme.sectionStyles.footer}>
			<div style={theme.overlayStyles.footer}>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					justifyContent="center"
				>
					<figure>
						<img src={logo} alt="Company Logo" style={{ maxWidth: "12dvw" }} />
					</figure>
					<Box align="start">
						<Typography variant="body5">sales@annkastles.com</Typography>
						<Typography variant="body5">
							DED License # 1107433 | ORN # 32150
						</Typography>
						<Typography variant="body5">
							PO Box: 128840, Dubai, United Arab Emirates
						</Typography>
					</Box>
				</Box>
				<Typography
					variant="body5"
					align="center"
					position="absolute"
					bottom="0"
				>
					<small>
						Copyright© 2023. AnnKastles Properties. All rights reserved.
					</small>
				</Typography>
			</div>
		</section>
	);
};
export default Footer;
