import { Typography, useTheme, useMediaQuery } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "../Assets/Styles/About.css";

const AboutMain = () => {
	const [ref, inView] = useInView({
		triggerOnce: true,
	});
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<section ref={ref} className={`fade-in ${inView ? "is-in-viewport" : ""}`}>
			<Typography variant="heading1" pt={isMobile ? 3 : 8}>
				ANNKASTLES
			</Typography>
			<Typography variant="body2" textAlign={"justify"} pt={isMobile ? 1 : 5}>
				We are investment consultants with a focus on luxury real estate in
				Dubai, UAE via our brokerage arm Annkastles Properties. We have got an
				unmatchable portfolio of luxury projects in the most sought-after
				addresses in Dubai. Annkastles was founded by seasoned professionals
				having vast global exposure with work experience in the Middle East and
				the luxury segment of the real estate industry. Driven by their desire
				to promote a certain art de vivre, the founders have built a lean and
				dynamic sales force of experienced property advisors focussing on the
				high-end luxury residential segment only. ​We are the match-makers
				connecting discerning international clients to the most boutique and
				luxury properties in Dubai. Simply put, Annkastles is the right place
				for anyone who is keen to tap into the very top-end of luxury properties
				(villas or apartments) in the UAE - off-plan or ready to move-in.
			</Typography>
		</section>
	);
};

export default AboutMain;
