import { useState, useEffect } from "react";
import data from "../Assets/Data/data.js";
import FetchPropertyImages from "../Functions/FetchPropertyImages.js";
import "../Assets/Styles/Project.css";
import CircularProgress from "@mui/material/CircularProgress";
import ProjectInfoCard from "../Components/ProjectInfoCard";
import ContentCard from "../Components/ContentCard";
import NotFound from "./NotFound";
import Header from "../Components/Header";
import Map from "../Components/Map";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import ContactCard from "../Components/ContactCard";
import ImageGallery from "../Components/ImageGallery";
import ImageSquare from "../Components/ImageSquare";
import SEO from "../Components/SEO";

const ProjectDetails = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const [ref, inView] = useInView({
		triggerOnce: true,
	});
	const { projectId } = useParams();
	const [propertyImages, setPropertyImages] = useState([]);
	const [loadingState, setLoadingState] = useState(true);
	//Find project data based on Id
	const project = data.find((project) => project.id === projectId);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const imageURLPromises = await FetchPropertyImages(projectId);
				const imageURLs = await Promise.all(imageURLPromises);
				setPropertyImages(imageURLs);
			} catch (error) {
				console.error("Error fetching property images: ", error);
			} finally {
				setLoadingState(false);
			}
		};
		fetchData();
	}, [projectId]);
	if (loadingState) {
		return (
			<Box
				display={"flex"}
				minHeight={"100vh"}
				justifyContent={"center"}
				alignItems={"center"}
				zIndex={loadingState ? 1 : -1}
			>
				<CircularProgress
					color="info"
					aria-describedby="progress bar"
					aria-busy="true"
				/>
			</Box>
		);
	}
	if (!project || propertyImages.length === 0) {
		return <NotFound />;
	}
	const {
		title,
		description,
		location,
		type,
		price,
		beds,
		baths,
		area,
		parking,
		lat,
		long,
		amenitiesAndFeatures,
		address,
	} = project;

	return (
		<Box>
			<SEO title={`${title} | ${location}`} description={description} />
			<Box position={"relative"}>
				<Header />
				<ImageGallery title={title} images={propertyImages} />
				<ProjectInfoCard
					title={title}
					location={location}
					price={price}
					beds={beds}
					baths={baths}
					area={area}
					type={type}
					parking={parking}
				/>
			</Box>

			<Box
				ref={ref}
				className={`fade-in ${inView ? "is-in-viewport" : ""}`}
				display="flex"
				flexDirection={isMobile || isMedium ? "column" : "row"}
				alignItems={isMobile || isMedium ? "center" : "flex-start"}
				justifyContent="center"
			>
				<ContentCard
					title={title}
					description={description}
					amenitiesAndFeatures={amenitiesAndFeatures}
					location={location}
					beds={beds}
					type={type}
					baths={baths}
					area={area}
					parking={parking}
					address={address}
				/>
				<Box
					maxWidth={isMobile || isMedium ? "100dvw" : "40dvw"}
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<ImageSquare images={propertyImages} title={title} />
					<Map
						latitude={lat}
						longitude={long}
						title={title}
						showFullWidthMap={isMobile || isMedium ? true : false}
					/>
				</Box>
			</Box>
			<ContactCard title={title} />
		</Box>
	);
};
export default ProjectDetails;
