import { useState } from "react";
import {
	Card,
	CardMedia,
	CardContent,
	Typography,
	useTheme,
	Button,
	useMediaQuery,
} from "@mui/material";

const PropertyCards = ({ imageUrl, title, price, developer, alt }) => {
	const [isHovered, setIsHovered] = useState(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const cardStyle = {
		position: "relative",
		height: developer ? "auto" : isMobile ? "25dvh" : "40dvh",
		width: "100%",
		overflow: "hidden",
		cursor: "pointer",
		transition: "transform 500ms",
	};

	const mediaStyle = {
		minHeight: "100%",
		minWidth: "100%",
		filter: developer
			? "grayscale(0%)"
			: isHovered
			? "grayscale(0%)"
			: "grayscale(100%)",
		transform: isHovered && "scale3d(1.3, 1.3, 1.3)",
		transition: "all 500ms",
		loading: "lazy",
	};

	const overlayStyle = {
		position: "absolute",
		top: 0,
		left: 0,
		minWidth: "100%",
		minHeight: "100%",
		backgroundColor: "rgba(0, 0, 0, 0.3)",
		color: "white",
		opacity: isHovered ? 1 : 0.9,
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
		alignItems: "flex-start",
		transition: "all 100ms",
	};

	const titlePriceContainerStyle = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		width: "100%",
		color: isHovered ? "white" : "#f5f5f5",
	};

	return (
		<Card
			sx={cardStyle}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<CardMedia component="img" alt={alt} src={imageUrl} sx={mediaStyle} />

			<CardContent sx={overlayStyle}>
				<div style={titlePriceContainerStyle}>
					<Typography variant={developer ? "body" : "body1"}>
						{title} {developer && `by ${developer}`} | {price}
					</Typography>
					{isHovered && !isMobile && (
						<Button sx={{ ...theme.buttonStyles.detailsButton }}>
							VIEW DETAILS +
						</Button>
					)}
				</div>
			</CardContent>
		</Card>
	);
};

export default PropertyCards;
