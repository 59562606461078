import { useEffect, useState, useCallback } from "react";
import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import Heading from "./Heading";
import TestimonialReadButton from "./TestimonialReadButton";
import TestimonialPairs from "./TestimonialPairs";
import testimonials from "../Assets/Data/testimonials.json";
import DubaiNight from "../Assets/Images/BackgroundImages/DubaiNight_1000.webp";
import MapDark from "../Assets/Images/BackgroundImages/MapDark_Compressed.webp";
import TestimonialMobile from "./TestimonialMobile";
import { yellow } from "@mui/material/colors";

const Testimonials = ({ autoplayInterval = 6000 }) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
	const mobileTestimonials = testimonials.slice(0, 4);

	const pairedArray = [];
	for (let i = 0; i < 4; i += 2) {
		const pair = testimonials.slice(i, i + 2);
		pairedArray.push(pair);
	}

	const handlePrevTestimonial = () => {
		setCurrentTestimonialIndex((prev) => {
			if (isMobile) {
				return prev === 0 ? mobileTestimonials.length - 1 : prev - 1;
			} else {
				return prev === 0 ? pairedArray.length - 1 : prev - 1;
			}
		});
	};
	const handleNextTestimonial = () => {
		setCurrentTestimonialIndex((prev) => {
			if (isMobile) {
				return prev === mobileTestimonials.length - 1 ? 0 : prev + 1;
			} else {
				return prev === pairedArray.length - 1 ? 0 : prev + 1;
			}
		});
	};
	// const handleNextTestimonial = useCallback(() => {
	// 	setCurrentTestimonialIndex((prev) => {
	// 		if (isMobile) {
	// 			return prev === mobileTestimonials.length - 1 ? 0 : prev + 1;
	// 		} else {
	// 			return prev === pairedArray.length - 1 ? 0 : prev + 1;
	// 		}
	// 	});
	// }, [mobileTestimonials.length, pairedArray.length]);
	useEffect(() => {
		const interval = setInterval(() => {
			handleNextTestimonial();
		}, autoplayInterval);
		return () => {
			clearInterval(interval);
		};
	}, [currentTestimonialIndex, handleNextTestimonial, autoplayInterval]);
	return (
		<Box
			pb={10}
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
			alignItems={"center"}
		>
			<Grid
				container
				display="flex"
				flexDirection="row"
				alignItems="flex-start"
				justifyContent="space-between"
			>
				<Grid item xs={12} md={6}>
					<img
						src={DubaiNight}
						alt="Dubai skyline at night"
						style={{
							maxWidth: "100%",
							minHeight: isMobile ? "70dvh" : "85dvh",
						}}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Box
						height={isMobile ? "36dvh" : "75dvh"}
						position={"relative"}
						ml={-3}
						mt={isMobile ? -1 : isDesktop ? 5 : 10}
						sx={{
							backgroundImage: `url(${MapDark})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							zIndex: "1",
							transition: "background-image 0.3s ease-in-out",
						}}
					>
						<div style={theme.overlayStyles.testimonial}>
							<Box
								pt={isDesktop ? 40 : 10}
								sx={{
									paddingBottom: isMobile && "80px",
								}}
								px={isMobile && 3}
								ml={isMobile ? 2.5 : isDesktop ? -60 : -14}
							>
								<Heading
									top={"WHAT OUR"}
									bottom={"CLIENTS ARE SAYING"}
									alignment={"flex-start"}
								/>
							</Box>
						</div>
					</Box>
				</Grid>
			</Grid>
			<Box
				bgcolor="#1B1B1B"
				px={isMobile ? 1 : 8}
				pt={isMobile ? 2.8 : 5}
				pb={isMobile ? 11 : 17}
				mt={isMobile ? -2 : isDesktop ? -40 : -30}
				align="center"
				justifyContent={"center"}
				alignItems={"center"}
				maxWidth={isMobile ? "100dvw" : "85dvw"}
				minHeight={isDesktop ? "30dvh" : isMobile ? "10dvh" : "40dvh"}
				maxHeight={isMobile ? "28vh" : "45dvh"}
				position="relative"
				zIndex={2}
			>
				{isMobile ? (
					<TestimonialMobile
						currentTestimonialIndex={currentTestimonialIndex}
						mobileTestimonials={mobileTestimonials}
					/>
				) : (
					<TestimonialPairs
						currentTestimonialIndex={currentTestimonialIndex}
						pairedArray={pairedArray}
					/>
				)}

				<TestimonialReadButton
					handlePrevTestimonial={handlePrevTestimonial}
					handleNextTestimonial={handleNextTestimonial}
				/>
			</Box>
		</Box>
	);
};

export default Testimonials;
