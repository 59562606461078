import { Box, useTheme, useMediaQuery } from "@mui/material";
import Subscribe from "../Components/Subscribe";
import SocialBar from "../Components/SocialBar";
import AboutMain from "../Components/AboutMain";
import AboutWhy from "../Components/AboutWhy";
import AboutHow from "../Components/AboutHow";
import WhyDubai from "../Components/WhyDubai";
import Header from "../Components/Header";
import SEO from "../Components/SEO";
import "../Assets/Styles/About.css";
import Background2 from "../Assets/Images/BackgroundImages/Background2_Compressed.webp";

const About = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<div>
			<SEO
				title={"Annkastles | About Us"}
				description={
					"We are investment consultants with a focus on luxury real estate in Dubai, UAE via our brokerage arm Annkastles Properties. We connect international clients to luxury properties in most sought-after addresses in Dubai, making Annkastles the go-to for top-tier luxury villas and apartments in the UAE, whether off-plan or ready to move in."
				}
			/>
			<Header backgroundImage={Background2} />
			{isMobile ? (
				<Box px={6}>
					<AboutMain />
					<AboutWhy />
					<AboutHow />
					<WhyDubai />
				</Box>
			) : (
				<Box px={14}>
					<AboutMain />
					<AboutWhy />
					<AboutHow />
					<WhyDubai />
				</Box>
			)}
			;
			<Subscribe />
			<SocialBar />
		</div>
	);
};

export default About;
