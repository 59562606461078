import { useTheme } from "@mui/system";
import { Typography, Box, useMediaQuery } from "@mui/material";

const ContentCard = ({ title, description, amenitiesAndFeatures, address }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

	return (
		<Box
			maxWidth={isMobile || isMedium ? "100dvw" : "52dvw"}
			mt={isMobile ? 0 : -3}
			px={isDesktop ? 10 : 4}
			py={isMobile ? 3 : isDesktop ? 10 : 5}
			display="flex"
			flexDirection="column"
			justifyContent="flex-start"
			alignItems="flex-start"
			bgcolor="white"
			zIndex="1"
		>
			<Typography variant="heading1">{title.toUpperCase()}</Typography>
			<Typography
				variant="body2"
				py={isMobile ? 2 : 4}
				sx={{ whiteSpace: "pre-line" }}
			>
				{description}
			</Typography>
			{amenitiesAndFeatures && (
				<>
					<Typography variant="heading3">AMENITIES & FEATURES</Typography>
					<Typography
						variant="body2"
						sx={{ whiteSpace: "pre-line" }}
						pb={isMobile ? 2 : isDesktop ? 6 : 4}
					>
						{amenitiesAndFeatures}
					</Typography>
				</>
			)}
			<Typography variant="heading3">LOCATION</Typography>
			<Typography variant="body2">{address}</Typography>
		</Box>
	);
};
export default ContentCard;
