import { Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/system";

const TestimonialPairs = ({ currentTestimonialIndex, pairedArray }) => {
	const theme = useTheme();
	return (
		<article>
			<Card
				sx={{
					background: "#1B1B1B",
					boxShadow: "none",
					display: "flex",
					flexDirection: "row",
					alignItems: "flex-start",
					justifyContent: "center",
					textAlign: "justify",
				}}
			>
				<CardContent>
					<Typography variant="body4">
						{pairedArray[currentTestimonialIndex][0].description}
					</Typography>
					<Typography variant="body4" pt={2}>
						- {pairedArray[currentTestimonialIndex][0].person}
					</Typography>
				</CardContent>
				<CardContent>
					<Typography sx={{ ...theme.verticalLine }}></Typography>
				</CardContent>
				<CardContent>
					<Typography variant="body4">
						{pairedArray[currentTestimonialIndex][1].description}
					</Typography>
					<Typography variant="body4" pt={2}>
						- {pairedArray[currentTestimonialIndex][1].person}
					</Typography>
				</CardContent>
			</Card>
		</article>
	);
};

export default TestimonialPairs;
