import TestimonialCard from "./TestimonialCard";
import { useInView } from "react-intersection-observer";
import "../Assets/Styles/Testimonial.css";

const AnimatedTestimonialCard = ({ description, person }) => {
	const [ref, inView] = useInView({
		triggerOnce: false,
	});
	return (
		<div ref={ref} className={`fade-in ${inView ? "is-in-viewport" : ""}`}>
			<TestimonialCard description={description} person={person} />
		</div>
	);
};
export default AnimatedTestimonialCard;
