import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../Assets/Images/logo.png";
import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Button,
	Menu,
	MenuItem,
	useMediaQuery,
	useTheme,
	Box,
} from "@mui/material";
import "../Assets/Styles/Header.css";

const Header = ({ backgroundImage }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

	const [isSticky, setIsSticky] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsSticky(true);
			} else {
				setIsSticky(false);
			}
		};
		window.addEventListener("scroll", handleScroll, { passive: true });
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const appBarStyle = {
		position: isSticky ? "fixed" : "absolute",
		padding: isDesktop ? "20px 30px" : "5px 20px 0 36px",
		top: "0",
		background: isSticky ? "rgba(0,0,0, 0.8)" : "rgba(255,255,255,0)",
		transition: "background 0.3s ease-in-out",
		boxShadow: "none",
	};
	const backgroundImageStyle = {
		backgroundImage: backgroundImage ? `url("${backgroundImage}")` : "none",
		backgroundSize: "cover",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		height: backgroundImage ? "60dvh" : "0",
		position: "relative",
	};
	return (
		<Box sx={backgroundImageStyle}>
			<AppBar sx={appBarStyle} color="inherit">
				<Toolbar>
					<Typography variant="h6" sx={{ flexGrow: 1 }}>
						<a href="/">
							<img
								src={logo}
								alt="Company Logo"
								style={{
									maxWidth: isDesktop ? "200px" : isMobile ? "135px" : "150px",
									paddingTop: isMobile ? "10px" : "5px",
								}}
							/>
						</a>
					</Typography>
					{isMobile ? (
						<>
							<IconButton
								size="large"
								color="inherit"
								aria-label="menu"
								edge="end"
								onClick={handleMenuClick}
								sx={{
									display: "block",
								}}
							>
								<MenuIcon sx={{ color: "#f5f5f5" }} />
							</IconButton>
							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
								color="inherit"
							>
								<MenuItem onClick={handleMenuClose}>
									<Link to="/" className="mobile-menu-link">
										Home
									</Link>
								</MenuItem>
								<MenuItem onClick={handleMenuClose}>
									<Link to="/about-us" className="mobile-menu-link">
										About
									</Link>
								</MenuItem>
								<MenuItem onClick={handleMenuClose}>
									<Link to="/active-projects" className="mobile-menu-link">
										Projects
									</Link>
								</MenuItem>
								<MenuItem onClick={handleMenuClose}>
									<Link to="/contact-us" className="mobile-menu-link">
										Contact
									</Link>
								</MenuItem>
							</Menu>
						</>
					) : (
						<>
							<Button>
								<Link className={isSticky ? "sticky-link" : "link"} to="/">
									Home
								</Link>
							</Button>
							<Button>
								<Link
									className={isSticky ? "sticky-link" : "link"}
									to="/about-us"
								>
									About
								</Link>
							</Button>
							<Button>
								<Link
									className={isSticky ? "sticky-link" : "link"}
									to="/active-projects"
								>
									Projects
								</Link>
							</Button>
							<Button>
								<Link
									className={isSticky ? "sticky-link" : "link"}
									to="/contact-us"
								>
									Contact
								</Link>
							</Button>
						</>
					)}
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default Header;
