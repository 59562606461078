import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyDkGPkpH6_ByMpWN02YFObQMXTBOlwleYM",
	authDomain: "annkastles-properties.firebaseapp.com",
	projectId: "annkastles-properties",
	storageBucket: "annkastles-properties.appspot.com",
	messagingSenderId: "820089887055",
	appId: "1:820089887055:web:72caeccda07b05e0880d3a",
	measurementId: "G-YB97TWQVXY",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
