import { useState } from "react";
import { useInView } from "react-intersection-observer";
import validator from "validator";
import "../Assets/Styles/Project.css";
import SubmitContactForm from "../Functions/SubmitContactForm";
import {
	Box,
	Container,
	Typography,
	Input,
	InputLabel,
	Grid,
	Button,
	useMediaQuery,
	useTheme,
} from "@mui/material";

const ContactCard = ({ title }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedium = useMediaQuery(theme.breakpoints.between("sm", "md"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
	const [ref, inView] = useInView({
		triggerOnce: false,
	});
	const initialUserData = {
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		message: "",
	};
	const [userData, setUserData] = useState(initialUserData);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};
	const validatePhoneNumber = (number) => {
		const isValidPhoneNumber = validator.isMobilePhone(number);
		return isValidPhoneNumber;
	};
	const validateEmail = (email) => {
		const isValidEmail = validator.isEmail(email);
		return isValidEmail;
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (!validatePhoneNumber(userData.phoneNumber)) {
			alert("Please enter a valid mobile number.");
			return;
		}
		if (!validateEmail(userData.email)) {
			alert("Please enter a valid email address.");
			return;
		}
		SubmitContactForm(e, { userData });
		setUserData(initialUserData);
	};
	return (
		<div ref={ref} className={`fade-in ${inView ? "is-in-viewport" : ""}`}>
			<Box
				sx={
					isMedium
						? theme.sectionStyles.contactPage
						: theme.sectionStyles.contactCard
				}
			>
				<div style={theme.overlayStyles.about}>
					<Container maxWidth={isMobile ? "md" : isDesktop ? "xl" : "lg"}>
						<Typography variant={title ? "heading6" : "heading1"} pb={10}>
							{title ? `INTERESTED IN ${title.toUpperCase()}?` : `CONTACT US`}
						</Typography>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={isDesktop ? 16 : 6}>
								<Grid item xs={6}>
									<InputLabel>FIRST NAME</InputLabel>
									<Input
										variant="standard"
										name="firstName"
										size="small"
										required
										value={userData.firstName}
										onChange={handleInputChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<InputLabel>LAST NAME</InputLabel>
									<Input
										variant="standard"
										name="lastName"
										size="small"
										required
										value={userData.lastName}
										onChange={handleInputChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<InputLabel>EMAIL ADDRESS</InputLabel>
									<Input
										variant="standard"
										name="email"
										type="email"
										size="small"
										required
										value={userData.email}
										onChange={handleInputChange}
									/>
								</Grid>
								<Grid item xs={6}>
									<InputLabel>PHONE NUMBER</InputLabel>
									<Input
										variant="standard"
										name="phoneNumber"
										type="tel"
										size="small"
										required
										value={userData.phoneNumber}
										onChange={handleInputChange}
										inputProps={{ maxLength: 15, minLength: 10 }}
									/>
								</Grid>
								<Grid item xs={12}>
									<InputLabel sx={{ marginBottom: "50px" }}>MESSAGE</InputLabel>
									<Input
										id="standard-multiline-static"
										variant="standard"
										name="message"
										size="small"
										multiline
										fullWidth
										maxRows={4}
										value={userData.message}
										onChange={handleInputChange}
									/>
								</Grid>
								<Grid item xs={12} align="right">
									<Button
										type="submit"
										sx={{ ...theme.buttonStyles.transparentButton }}
									>
										<span style={theme.lineStyles.line4}></span>SUBMIT
									</Button>
								</Grid>
							</Grid>
						</form>
					</Container>
				</div>
			</Box>
		</div>
	);
};
export default ContactCard;
