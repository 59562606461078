import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import "../Assets/Styles/Header.css";

const Map = ({ latitude, longitude, title, showFullWidthMap }) => {
	const apiKey = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
	const { isLoaded, loadError } = useLoadScript({
		googleMapsApiKey: apiKey,
	});
	const center = {
		lat: parseFloat(latitude) || 25.19199259676762,
		lng: parseFloat(longitude) || 55.28178018514728,
	};
	if (loadError) {
		console.error("Google Maps Loading Error:", loadError);
		return <div>Error loading Google Maps</div>;
	}

	return (
		<div
			className={
				showFullWidthMap ? "full-width-container-style" : "container-style"
			}
		>
			{!isLoaded ? (
				<Box
					display="flex"
					flexDirection={"column"}
					justifyContent="center"
					alignItems="center"
					minHeight={"100%"}
				>
					<CircularProgress color="inherit" />
				</Box>
			) : (
				<GoogleMap
					mapContainerClassName="map-container"
					options={{ mapId: "e6e039c4a627db86" }}
					center={center}
					zoom={12}
				>
					<MarkerF position={center} title={title} />
				</GoogleMap>
			)}
		</div>
	);
};

export default Map;
