import React from "react";
import { Link } from "react-router-dom";
import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";

const WhoWeAre = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const textContainerStyle = {
		textAlign: "center",
		maxWidth: isMobile ? "75%" : "55%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "10px 0px",
	};

	return (
		<Box sx={{ ...theme.sectionStyles.whoWeAre }} align="center" my={10} mx={0}>
			<div style={theme.overlayStyles.about}>
				<Box sx={textContainerStyle}>
					<Typography variant="heading">WHO WE ARE</Typography>
					<Typography
						variant="body1"
						sx={{
							textAlign: "center",
							margin: "2.5em 0em",
						}}
					>
						At Annkastles, we connect globally coveted and influential clients
						to the finest luxury properties in Dubai. With our lean and strong
						team of sophisticated property specialists, we focus exclusively on
						high-end premium real estate. Our commitment to providing a seamless
						buying experience for our esteemed clientele is what sets us apart.
						We always aim to create a reputation as trusted advisors to our
						clients, providing practical and diverse real estate investment
						solutions where we see growth potential driven by global economic
						and macro trends
					</Typography>
					<Button
						component={Link}
						sx={{ ...theme.buttonStyles.transparentButton, marginTop: "10px" }}
						to="/about-us"
					>
						<span style={theme.lineStyles.line2}></span>Learn More +
					</Button>
				</Box>
			</div>
		</Box>
	);
};

export default WhoWeAre;
