import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Background3 from "../Assets/Images/BackgroundImages/Background3_Compressed.webp";
import Background1 from "../Assets/Images/BackgroundImages/Background1_Compressed.webp";
import AvaInterior from "../Assets/Images/BackgroundImages/AvaInterior_Compressed.webp";
import OrlaCover from "../Assets/Images/CoverImages/OrlaCover_800.webp";

const generateLineStyle = (left) => ({
	position: "absolute",
	content: '""',
	top: "50%",
	width: "50px",
	height: "1.1px",
	backgroundColor: "grey",
	left,
});

const generateOverlayStyle = (backgroundColor, justifyContent) => ({
	position: "absolute",
	top: "0",
	left: "0",
	minWidth: "100%",
	minHeight: "100%",
	display: "flex",
	alignItems: "center",
	flexDirection: "column",
	overflow: "hidden",
	loading: "lazy",
	justifyContent,
	backgroundColor,
});
const generateSectionStyle = (backgroundImage, minHeight) => ({
	backgroundSize: "cover",
	backgroundPosition: "center",
	position: "relative",
	boxSizing: "border-box",
	color: "#f5f5f5",
	maxWidth: "100dvw",
	transition: "background-image 0.3s ease-in-out",
	loading: "lazy",
	backgroundImage,
	minHeight,
});
// Define the custom theme
const theme = createTheme({
	margin: "0",
	padding: "0",
	boxSizing: "border-box",
	palette: {
		primary: {
			main: "#ffffff", // White
		},
		secondary: {
			main: "#1B1B1B", // Black
		},
		accent: {
			main: "#0c659a", // Logo Blue
		},
		background: {
			default: "#f5f5f5", // Light gray or use e8e8e8, slightly darker than current
		},
		text: {
			primary: "#1B1B1B",
			secondary: "#333333", // Dark gray
		},
		overlayBackground: "rgba(173,216,230,0.5)", // Light grey with transparency
	},

	typography: {
		fontFamily: ['"Source Sans 3"', "sans-serif"].join(","),
		heading: {
			fontFamily: '"Barlow Semi Condensed", sans-serif',
			fontSize: "clamp(2.1rem,5vw,6.5rem)",
			letterSpacing: "-1.3px",
			fontWeight: "600",
			lineHeight: "clamp(2.5rem,5vw,6.5rem)",
			display: "inline-block",
			textAlign: "center",
			color: "inherit",
		},
		heading1: {
			fontFamily: '"Barlow Semi Condensed", sans-serif',
			fontSize: "clamp(1.5rem,3.4vw,4.9rem)",
			letterSpacing: "-1.55px",
			fontWeight: "600",
			lineHeight: "72px",
			textAlign: "center",
			display: "inline-block",
		},
		heading2: {
			fontFamily: '"Barlow Semi Condensed", sans-serif',
			fontSize: "clamp(0.9rem,2.8vw,3rem)",
			letterSpacing: "-1.6px",
			fontWeight: "500",
			lineHeight: "65px",
			textAlign: "center",
			display: "inline-block",
			color: "#0c659a",
		},
		heading3: {
			fontFamily: '"Barlow Semi Condensed", sans-serif',
			fontSize: "clamp(1.2rem,2vw,4.7rem)",
			letterSpacing: "-1.4px",
			fontWeight: "600",
			lineHeight: "82px",
			textAlign: "left",
			display: "inline-block",
		},
		heading4: {
			fontFamily: '"Barlow Semi Condensed", sans-serif',
			fontSize: "clamp(1.1rem,1.3vw,5rem)",
			letterSpacing: "-1.5px",
			fontWeight: "100",
			color: "#666666",
			marginTop: "-25px",
			textAlign: "left",
			display: "block",
		},
		heading5: {
			fontFamily: '"Barlow Semi Condensed", sans-serif',
			fontSize: "clamp(1.15rem,2vw,5rem)",
			letterSpacing: "-1.55px",
			fontWeight: "600",
			lineHeight: "82px",
			textAlign: "left",
			display: "block",
			color: "#0c659a",
		},
		heading6: {
			fontFamily: '"Barlow Semi Condensed", sans-serif',
			fontSize: "clamp(1.2rem,2.8vw,4rem)",
			letterSpacing: "-1.35px",
			fontWeight: "550",
			lineHeight: "72px",
			textAlign: "center",
			display: "inline-block",
		},
		body: {
			fontFamily: '"Source Sans 3", sans-serif',
			fontSize: "clamp(0.7rem, 1.4vw, 1.6rem)",
			fontWeight: "800",
			lineHeight: "1.7",
		},
		body1: {
			fontFamily: '"Source Sans 3", sans-serif',
			fontWeight: "600",
			lineHeight: "1.7",
			fontSize: "clamp(0.8rem, 1.15vw, 1.6rem)",
		},
		body2: {
			fontFamily: '"Source Sans 3", sans-serif',
			fontSize: "clamp(0.9rem, 1.1vw, 1.4rem)",
			fontWeight: "300",
			lineHeight: "1.7",
			color: "#666666",
		},
		body3: {
			fontFamily: '"Source Sans 3", sans-serif',
			fontSize: "clamp(0.8rem, 1vw, 1.4rem)",
			fontWeight: "300",
			lineHeight: "1.7",
			color: "#666666",
			display: "block",
		},
		body4: {
			fontFamily: '"Source Sans 3", sans-serif',
			fontSize: "clamp(0.6rem, 1vw, 1.4rem)",
			fontWeight: "300",
			lineHeight: "1.7",
			display: "block",
			color: "#f5f5f5",
		},
		body5: {
			fontFamily: '"Source Sans 3", sans-serif',
			fontSize: "clamp(0.8rem, 1.01vw, 1.4rem)",
			fontWeight: "400",
			lineHeight: "1.7",
			color: "#555555",
			display: "block",
		},
	},

	buttonStyles: {
		primaryButton: {
			backgroundColor: "transparent",
			color: "#fff",
			padding: "0px 15px",
			fontSize: "clamp(0.6rem, 1.0vw, 1.1rem)",
			fontWeight: "700",
			textDecoration: "none",
		},

		secondaryButton: {
			position: "relative",
			boxSizing: "border-box",
			alignItems: "center",
			justifyContent: "center",
			border: "1.1px solid #b3b3b3",
			backgroundColor: "#1B1B1B",
			color: "#fff",
			padding: "15px 50px",
			borderRadius: "0",
			textDecoration: "none",
			fontSize: "clamp(0.6rem, 1vw, 1.1rem)",
			transition: "all 0.6s",
			"&:hover": {
				color: "#1B1B1B",
				backgroundColor: "#f5f5f5",
				fontWeight: "800",
			},
			"@media(max-width:786px)": {
				padding: "10px 30px",
			},
		},

		transparentButton: {
			border: "1.1px solid grey",
			color: "white",
			padding: "15px 50px",
			borderRadius: "0",
			textDecoration: "none",
			position: "relative",
			fontWeight: "600",
			fontSize: "clamp(0.6rem, 1vw, 1.1rem)",
			transition: "all 0.6s",
			"&:hover": {
				fontWeight: "800",
				transform: "scale3d(1.05,1.05,1.05)",
			},
		},
		detailsButton: {
			border: "none",
			backgroundColor: "#1B1B1B",
			color: "white",
			padding: "8px 16px",
			borderRadius: "0",
			textDecoration: "none",
			alignSelf: "flex-end",
			fontSize: "clamp(0.6rem, 0.9vw, 1.5rem)",
			fontWeight: "700",
			transition: "all 0.6s",
			"&:hover": {
				color: "black",
				backgroundColor: "rgba(255,255,255,0.6)",
				fontWeight: "800",
			},
		},
		hideDetailsButton: {
			writingMode: "vertical-lr",
			textOrientation: "sideways",
			letterSpacing: "1.55px",
			border: "none",
			backgroundColor: "#1B1B1B",
			color: "white",
			padding: "60px 8px",
			borderRadius: "0",
			textDecoration: "none",
			fontSize: "clamp(0.6rem, 1vw, 1.5rem)",
			fontWeight: "600",
			transition: "all 0.6s",
			"&:hover": {
				color: "black",
				backgroundColor: "white",
				fontWeight: "800",
				borderRight: "1px solid grey",
			},
		},
	},
	sectionStyles: {
		subscribe: generateSectionStyle(`url(${Background1})`, "100dvh"),
		testimonials: generateSectionStyle(`url(${Background1})`, "240dvh"),
		whoWeAre: generateSectionStyle(`url(${AvaInterior})`, "80dvh"),
		contactCard: generateSectionStyle(`url(${OrlaCover})`, "94dvh"),
		contactPage: generateSectionStyle(`url(${OrlaCover})`, "70dvh"),
		footer: generateSectionStyle(`url(${Background3})`, "22dvh"),
	},
	lineStyles: {
		line1: generateLineStyle("-10%"),
		line2: generateLineStyle("-13%"),
		line3: generateLineStyle("-13%"),
		line4: generateLineStyle("-18%"),
		line5: generateLineStyle("88%"),
		line6: generateLineStyle("-12%"),
	},
	verticalLine: {
		content: '""',
		width: "1.5px",
		height: "110px",
		margin: "auto",
		backgroundColor: "grey",
	},
	overlayStyles: {
		about: generateOverlayStyle("rgba(0, 0, 0, 0.87)", "center"),
		contact: generateOverlayStyle("rgba(0, 0, 0, 0.75)", "center"),
		testimonial: generateOverlayStyle("rgba(255,255,255,0.8", "flex-start"),
		testimonial2: generateOverlayStyle("rgba(0,0,0,0.1)", "center"),
		footer: generateOverlayStyle("rgba(255, 255, 255, 0.8)", "center"),
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},

	components: {
		MuiInput: {
			styleOverrides: {
				root: {
					fontSize: "clamp(0.6rem, 0.9vw, 1.1rem)",
					letterSpacing: "1.26px",
					minHeight: "32px",
					border: "none",
					width: "100%",
					display: "block",
					borderBottom: "0.6px solid rgba(255, 255, 255, 0.8)",
					"& .MuiInputBase-input": {
						color: "#fff",
						backgroundColor: "transparent",
						lineHeight: "20px",
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					fontFamily: '"Roboto",sans-serif',
					fontSize: "clamp(0.6rem, 0.7vw, 1.1rem)",
					fontWeight: "400",
					color: "inherit",
				},
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					borderRadius: "0%",
				},
			},
		},
	},
});

const CustomThemeProvider = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};

export default CustomThemeProvider;
