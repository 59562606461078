import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "../Assets/Styles/About.css";
import DubaiExpo from "../Assets/Images/BackgroundImages/DubaiExpo.webp";

const AboutHow = () => {
	const [ref, inView] = useInView({
		triggerOnce: true,
	});
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	const description =
		"Annkastles sits right at the top of the pyramid and deals only with Crème de la crème projects in Dubai. Our exclusive approach to the luxury-only segment gives us a vantage point and positions Annkastles uniquely in the industry​. Our key strength is our consultative approach. We help get you the options to choose from, tailored exactly as per your requirement. We believe in sharing information transparently with our clients which help build rapport and trust. We take pride in our transparent dealings with the clients​. We adopt a solution-centric approach to offering the right advice to our clients. We believe a sale is a by-product if we are on the path of providing the right solution to our clients​. Locally focused, yet globally aware - we strive to provide practical and diverse real estate investment solutions where we see growth potential driven by global macro-economic and local trends.";

	return (
		<section ref={ref} className={`fade-in ${inView ? "is-in-viewport" : ""}`}>
			<Grid container className="section-container" pb={isMobile ? 6 : 12}>
				{isMobile ? (
					<>
						<Grid item xs={12} md={6}>
							<img
								className="section-image"
								src={DubaiExpo}
								alt="Expo, Dubai"
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="heading1">HOW ARE WE DIFFERENT</Typography>
							<Typography variant="body2" textAlign={"justify"}>
								{description}
							</Typography>
						</Grid>
					</>
				) : (
					<>
						<Grid item xs={12} md={6}>
							<Typography variant="heading1">HOW ARE WE DIFFERENT</Typography>
							<Typography variant="body2" textAlign={"justify"} pt={5}>
								{description}
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} pl={4}>
							<img
								className="section-image"
								src={DubaiExpo}
								alt="Expo, Dubai"
							/>
						</Grid>
					</>
				)}
			</Grid>
		</section>
	);
};
export default AboutHow;
