import {
	Card,
	Button,
	Box,
	Typography,
	IconButton,
	useTheme,
	useMediaQuery,
	CardContent,
	CardMedia,
} from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import LocationOnSharpIcon from "@mui/icons-material/LocationOnSharp";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import CallSharpIcon from "@mui/icons-material/CallSharp";
import "../Assets/Styles/Project.css";
import logo from "../Assets/Images/logo.png";

const MiniContactCard = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
	const [showInfo, setShowInfo] = useState(true);
	const [ref, inView] = useInView({
		triggerOnce: true,
	});
	const handleToggle = () => {
		setShowInfo((prevState) => !prevState);
	};
	return (
		<Box
			ref={ref}
			className={`slide-in ${inView ? "is-in-viewport" : ""}`}
			component={"span"}
			display="flex"
			flexDirection="row"
			minHeight={isMobile ? "30dvh" : isDesktop ? "30dvh" : "35dvh"}
			maxWidth={isMobile ? "78dvw" : "35dvw"}
			mx={0}
			justifyContent={"flex-end"}
			position={"absolute"}
			sx={{
				top: isMobile ? "18%" : isDesktop ? "33%" : "23%",
				right: "0",
			}}
		>
			<Button
				onClick={handleToggle}
				sx={{
					...theme.buttonStyles.hideDetailsButton,
				}}
			>
				<Typography
					sx={{
						textOrientation: "sideways",
						writingMode: "vertical-lr",
						fontWeight: "500",
					}}
				>
					{showInfo ? "HIDE  " : "SHOW "} DETAILS
				</Typography>
			</Button>
			{showInfo && (
				<Card
					sx={{
						width: isMobile ? "70dvw" : "25dvw",
						background: "rgba(255,255,255,0.99)",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "flex-start",
						padding: isMobile
							? "30px 0px 0px 0px"
							: isDesktop
							? "0px 10px"
							: "30px 10px",
					}}
				>
					<CardMedia>
						<img
							src={logo}
							alt="company logo"
							style={{
								width: isMobile ? "110px" : isDesktop ? "200px" : "150px",
								marginLeft: isMobile ? "20px" : "15px",
							}}
						/>
					</CardMedia>
					<CardContent>
						<Typography variant="body2">
							<IconButton aria-label="email icon">
								<EmailSharpIcon />
							</IconButton>
							sales@annkastles.com
						</Typography>
						<Typography variant="body2">
							<IconButton aria-label="telephone call icon">
								<CallSharpIcon />
							</IconButton>
							+971585290321
						</Typography>
						<Typography variant="body2">
							<IconButton aria-label="location icon">
								<LocationOnSharpIcon />
							</IconButton>
							128840, Dubai, {isMobile ? "UAE" : "United Arab Emirates"}
						</Typography>
					</CardContent>
				</Card>
			)}
		</Box>
	);
};
export default MiniContactCard;
