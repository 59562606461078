import { Typography, useTheme, useMediaQuery, Grid } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "../Assets/Styles/About.css";

const WhyDubai = () => {
	const [ref, inView] = useInView({
		triggerOnce: true,
	});
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<section ref={ref} className={`fade-in ${inView ? "is-in-viewport" : ""}`}>
			<Grid container pb={isMobile ? 6 : 8}>
				<Typography variant="heading1" pb={isMobile ? 0 : 4}>
					WHY DUBAI
				</Typography>
				<Typography variant="body2" textAlign={"justify"}>
					We at Annkastles have chosen to keep our market focus precise, by
					targeting only Dubai. Because Dubai’s real-estate market size is
					increasing leaps and bounds in recent times. Although there are myriad
					industry reports that may be overwhelming to go through, but each
					confirming the high market growth due to multiple factors, in simple
					terms, the key ones are: <br />
					Dubai true to its style turned a problem into an opportunity by
					immaculately handling the pandemic situation in 2020-21. There was an
					upsurge in the demand that gave boost to the real-estate market which
					was on a down curve just before the pandemic.
					<br />
					Further, the geo political situations also turned new investors
					especially the UHNWI (Ultra High Net Worth Individuals) towards Dubai.
					The timing of the golden visa programme especially for the luxury
					segment couldn’t have been more perfect, stirring the interest from
					affluent global communities to invest in the emirate. <br />
					The data supports the fact and the new trend that the global UHNWI are
					looking for new markets other than the ones getting saturated
					(Newyork, L.A, Monaco, London, Paris, Hongkong etc.), clearly Dubai is
					one of the sought after destinations in that regard which offers a
					huge head room for psft (per square feet) price growth in luxury
					segment. <br />
					For a very tech savvy Dubai, global investors owning digital assets,
					view UAE as the destination that supports innovation and digital
					currencies (At Annakastles we do facilitate the crypto payments for
					the investors and buyers who want to buy using crypto currency)
					<br />
					Underlying to the core supporting the above reasons, its Dubai’s
					proven reputation of transparent governance and inclusive culture
					attract new buyers to the emirate., both investors as well as new
					end-users.
				</Typography>
			</Grid>
		</section>
	);
};

export default WhyDubai;
