import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import CustomThemeProvider from "./Components/CustomThemeProvider";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Projects from "./Pages/Projects";
import About from "./Pages/About";
import NotFound from "./Pages/NotFound";
import Footer from "./Components/Footer";
import ProjectDetails from "./Pages/ProjectDetails";
import ClientsTestimonials from "./Pages/ClientsTestimonials";
import ScrollToTop from "./Components/ScrollToTop";
function App() {
	return (
		<CustomThemeProvider>
			<Router>
				<ScrollToTop />
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route path="/about-us" element={<About />} />
					<Route path="/active-projects" element={<Projects />} />
					<Route path="/contact-us" element={<Contact />} />
					<Route
						path="/active-projects/:projectId"
						element={<ProjectDetails />}
					/>
					<Route path="*" element={<NotFound />} />
					<Route
						path="/client-testimonials"
						element={<ClientsTestimonials />}
					/>
				</Routes>
				<Footer />
			</Router>
		</CustomThemeProvider>
	);
}

export default App;
