import { db } from "../Config/firebase-config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const SubmitContactForm = async (e, { userData }) => {
	try {
		await addDoc(collection(db, "Customer Inquiries"), {
			...userData,
			createdAt: serverTimestamp(),
		});
		alert("Your response has been submitted. Thank you for contacting us!");
	} catch (error) {
		console.log(error);
	}
};
export default SubmitContactForm;
