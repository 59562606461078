import React from "react";
import FeaturedProjects from "../Components/FeaturedProjects";
import WhoWeAre from "../Components/WhoWeAre";
import Testimonials from "../Components/Testimonials";
import Subscribe from "../Components/Subscribe";
import SocialBar from "../Components/SocialBar";
import Header from "../Components/Header";
import Hero from "../Components/Hero";

const Home = () => {
	return (
		<>
			<Header />
			<Hero />
			<FeaturedProjects />
			<WhoWeAre />
			<Testimonials />
			<Subscribe />
			<SocialBar />
		</>
	);
};
export default Home;
