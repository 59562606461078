import {
	Box,
	Typography,
	ImageList,
	ImageListItem,
	Card,
	CardContent,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import testimonials from "../Assets/Data/testimonials.json";
import Header from "../Components/Header";
import AnimatedTestimonialCard from "../Components/AnimatedTestimonialCard";
import SocialBar from "../Components/SocialBar";
import Subscribe from "../Components/Subscribe";
import SEO from "../Components/SEO";
import Background2 from "../Assets/Images/BackgroundImages/Background2_Compressed.webp";

const ClientsTestimonials = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const testList = testimonials.map((mon) => {
		return (
			<ImageListItem key={mon.id}>
				<AnimatedTestimonialCard
					description={mon.description}
					person={mon.person}
				/>
			</ImageListItem>
		);
	});

	return (
		<>
			<SEO
				title={"Hear From Our Clients"}
				description={
					"Discover firsthand testimonials showcasing exceptional service, personalized expertise, and the epitome of refined living. Elevate your expectations with Annkastles – where every testimonial is a testament to extraordinary real estate experiences."
				}
			/>
			<Header backgroundImage={Background2} />
			<SocialBar />
			<Box align="center">
				<ImageList
					variant="masonry"
					cols={isMobile ? 1 : 2}
					gap={isMobile ? 20 : 60}
					sx={{
						overflow: "hidden",
						margin: isMobile ? "40px 30px" : "-30px 100px 100px 100px",
					}}
				>
					<ImageListItem>
						<Card
							sx={{
								background: "#fff",
								textAlign: "center",
								maxWidth: isMobile ? "80dvw" : "30dvw",
								boxSizing: "border-box",
								padding: isMobile ? "0px 4px" : "50px 30px",
							}}
						>
							<CardContent>
								<Typography variant="heading" component={"h1"}>
									WHAT OUR CLIENTS ARE SAYING
								</Typography>
							</CardContent>
						</Card>
					</ImageListItem>
					{testList}
				</ImageList>
			</Box>

			<Subscribe />
		</>
	);
};
export default ClientsTestimonials;
