import React from "react";
import ContactCard from "../Components/ContactCard";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Header from "../Components/Header";
import SocialBar from "../Components/SocialBar";
import SEO from "../Components/SEO";
import MiniContactCard from "../Components/MiniContactCard";
import ContactUs from "../Assets/Images/BackgroundImages/ContactUs_Compressed.webp";

import Map from "../Components/Map";

const Contact = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<div>
			<SEO
				title={"Annkastles | Contact Us"}
				description={
					"Reach out to us at Annkastles for an unparalleled experience in luxury real estate. sales@annkastles.com. +971585290321. 128840, Dubai, United Arab Emirates."
				}
			/>
			<Box bgcolor={"#f5f5f5"}>
				<Header backgroundImage={ContactUs} />
				<Box align="left" mt={isMobile ? 0 : -3} px={isMobile ? 0 : 8}>
					<ContactCard />
				</Box>
				<MiniContactCard />
				<SocialBar />
				<Box
					px={!isMobile && 8}
					mr={!isMobile && 8}
					overflow={"clip"}
					pt={2}
					pb={5}
				>
					<Map
						latitude={25.19199259676762}
						longitude={55.28178018514728}
						title={"Business Bay, Dubai, United Arab Emirates"}
						showFullWidthMap={true}
					/>
				</Box>
			</Box>
		</div>
	);
};
export default Contact;
