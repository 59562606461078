import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";

const NotFound = () => {
	return (
		<>
			<Box align="center" py={30} minHeight={"100vh"}>
				<Typography variant="heading">404 - Page Not Found</Typography>
				<Typography variant="body1" py={4}>
					Sorry, the page you are looking for does not exist.
				</Typography>
				<Button>
					<Link to="/">Go back to Home page</Link>
				</Button>
			</Box>
		</>
	);
};

export default NotFound;
