import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import {
	Typography,
	Button,
	Card,
	CardContent,
	Box,
	IconButton,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import HotelIcon from "@mui/icons-material/Hotel";
import BathtubIcon from "@mui/icons-material/Bathtub";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import LocalParkingRoundedIcon from "@mui/icons-material/LocalParkingRounded";
import VillaIcon from "@mui/icons-material/Villa";
import "../Assets/Styles/Project.css";

const ProjectInfoCard = ({
	title,
	location,
	price,
	beds,
	baths,
	area,
	type,
	parking,
}) => {
	const theme = useTheme();
	const [showDetails, setShowDetails] = useState(true);
	const [ref, inView] = useInView({
		triggerOnce: true,
	});
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const handleToggle = () => {
		setShowDetails((prevState) => !prevState);
	};
	return (
		<Box
			ref={ref}
			className={`slide-in ${inView ? "is-in-viewport" : ""}`}
			component={"span"}
			display="flex"
			flexDirection="row"
			maxHeight={isMobile ? "45dvh" : "60dvh"}
			minWidth={"30dvw"}
			mx={0}
			justifyContent={"flex-end"}
			position={"absolute"}
			sx={{
				top: isMobile ? "25%" : "30%",
				right: "0",
			}}
		>
			<Button
				onClick={handleToggle}
				sx={{
					...theme.buttonStyles.hideDetailsButton,
				}}
			>
				<Typography
					sx={{
						textOrientation: "sideways",
						writingMode: "vertical-lr",
						fontWeight: "500",
					}}
				>
					{showDetails ? "HIDE  " : "SHOW "} DETAILS
				</Typography>
			</Button>
			{showDetails && (
				<Card
					sx={{
						minWidth: "100%",
						background: "rgba(255,255,255,0.85)",
						display: "flex",
						alignItems: "center",
					}}
				>
					<CardContent>
						<Typography
							variant="heading3"
							sx={{ lineHeight: isMobile ? "62px" : "82px" }}
						>
							{title.toUpperCase()}
						</Typography>
						<Typography
							variant="heading4"
							sx={{
								lineHeight: isMobile && "32px",
							}}
						>
							{location}
						</Typography>
						<Typography
							variant="heading5"
							sx={{ lineHeight: isMobile ? "32px" : "82px" }}
						>
							{price}
						</Typography>
						<Typography variant="body3">
							<IconButton>
								<VillaIcon />
							</IconButton>
							{type.toUpperCase()}
						</Typography>
						<Typography variant="body3">
							<IconButton>
								<HotelIcon />
							</IconButton>
							<b>{beds}</b>&nbsp;&nbsp;BEDS
						</Typography>
						<Typography variant="body3">
							<IconButton>
								<BathtubIcon />
							</IconButton>
							<b>{baths}</b>&nbsp;&nbsp;BATHS
						</Typography>
						{parking > "0" && (
							<Typography variant="body3">
								<IconButton>
									<LocalParkingRoundedIcon />
								</IconButton>
								<b>{parking}</b>&nbsp;&nbsp;PARKING
							</Typography>
						)}

						<Typography variant="body3">
							<IconButton>
								<SquareFootIcon />
							</IconButton>
							<b>{area}</b>&nbsp;&nbsp;SQ. FT
						</Typography>
					</CardContent>
				</Card>
			)}
		</Box>
	);
};
export default ProjectInfoCard;
