import React, { useState } from "react";
import {
	Input,
	InputLabel,
	Typography,
	Grid,
	Button,
	Container,
	useTheme,
	Box,
	useMediaQuery,
} from "@mui/material";
import SubmitSubscriptionRequest from "../Functions/SubmitSubscriptionRequest";
const Subscribe = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

	const initialUserData = {
		name: "",
		email: "",
		message: "",
	};
	const [inputData, setInputData] = useState(initialUserData);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setInputData({ ...inputData, [name]: value });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		SubmitSubscriptionRequest(e, { inputData });
		setInputData(initialUserData);
	};

	return (
		<Box sx={{ ...theme.sectionStyles.subscribe }}>
			<div style={theme.overlayStyles.about}>
				<Typography variant="heading">CONNECT WITH US</Typography>
				<Typography
					paragraph
					variant="body1"
					pt={isDesktop ? 5 : 3}
					pb={isDesktop ? 16 : 12}
					textAlign="center"
				>
					Exquisite Realms Await
				</Typography>
				<Container maxWidth={isMobile ? "sm" : isDesktop ? "lg" : "md"}>
					<form onSubmit={handleSubmit}>
						<Grid container spacing={isDesktop ? 12 : 8}>
							<Grid item xs={6}>
								<InputLabel>NAME</InputLabel>
								<Input
									variant="standard"
									name="name"
									size="small"
									required
									value={inputData.name}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<InputLabel>EMAIL ADDRESS</InputLabel>
								<Input
									variant="standard"
									name="email"
									type="email"
									size="small"
									required
									value={inputData.email}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<InputLabel sx={{ marginBottom: "50px" }}>MESSAGE</InputLabel>
								<Input
									id="standard-multiline-static"
									variant="standard"
									name="message"
									size="small"
									multiline
									fullWidth
									maxRows={4}
									value={inputData.message}
									onChange={handleInputChange}
								/>
							</Grid>
							<Grid item xs={12} align="center">
								<Button
									type="submit"
									sx={{ ...theme.buttonStyles.transparentButton }}
								>
									<span style={theme.lineStyles.line3}></span>SUBMIT
								</Button>
							</Grid>
						</Grid>
					</form>
				</Container>
			</div>
		</Box>
	);
};
export default Subscribe;
