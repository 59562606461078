import { useState, useEffect, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { styled } from "@mui/system";
import "../Assets/Styles/Project.css";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const SliderContainer = styled("section")({
	position: "relative",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	height: "98dvh",
	minWidth: "100dvw",
	overflow: "hidden",
});

const SliderImage = styled("img")({
	position: "absolute",
	width: "100%",
	minHeight: "100%",
	maxHeight: "120%",
	maxWidth: "100%",
	// objectFit: "cover",
	repeat: "no-repeat",
	animation: "fadeIn 2s",
	transition: "fadeIn 2s ease-in-out",
});

const ImageGallery = ({ images, title, autoplayInterval = 5000 }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [ref, inView] = useInView({
		triggerOnce: true,
	});
	const handlePrevImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === 0 ? images.length - 1 : prevIndex - 1
		);
	};

	const handleNextImage = useCallback(() => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === images.length - 1 ? 0 : prevIndex + 1
		);
	}, [images.length]);
	const handleImageError = () => {
		const errorMessage = `Error loading ${title} image at index, ${currentImageIndex}`;
		// images[currentImageIndex] = images[0]; // Replace the problematic image with the placeholder image
		console.log(errorMessage);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			handleNextImage();
		}, autoplayInterval);
		return () => {
			clearInterval(interval);
		};
	}, [currentImageIndex, handleNextImage, autoplayInterval]);

	const imageName = images[currentImageIndex]
		? decodeURIComponent(
				images[currentImageIndex].substring(
					images[currentImageIndex].lastIndexOf("/") + 1,
					images[currentImageIndex].lastIndexOf(".")
				)
		  )
		: "";
	const lastPart = imageName.substring(imageName.lastIndexOf("/") + 1);
	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
		>
			<SliderContainer
				ref={ref}
				className={`slide-out ${inView ? "is-in-viewport" : ""}`}
				sx={{ height: isMobile ? "60dvh" : "98dvh" }}
			>
				<SliderImage
					src={images[currentImageIndex]}
					alt={lastPart}
					onError={handleImageError}
				/>
				<IconButton
					onClick={handlePrevImage}
					aria-label="Previous Image"
					sx={{
						position: "absolute",
						top: "87%",
						right: isMobile ? "15%" : "7%",
						color: "white",
						fontSize: isMobile ? "3rem" : "5rem",
					}}
				>
					<NavigateBeforeIcon fontSize="inherit" />
				</IconButton>
				<IconButton
					onClick={handleNextImage}
					aria-label="Next Image"
					sx={{
						position: "absolute",
						top: "87%",
						right: "2%",
						color: "white",
						fontSize: isMobile ? "3rem" : "5rem",
					}}
				>
					<NavigateNextIcon fontSize="inherit" />
				</IconButton>
			</SliderContainer>
		</Box>
	);
};

export default ImageGallery;
