import {
	Box,
	Button,
	IconButton,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const TestimonialReadButton = ({
	handlePrevTestimonial,
	handleNextTestimonial,
}) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Box
			sx={{
				position: "absolute",
				boxSizing: "border-box",
				display: "flex",
				justifyContent: "center",
				left: isMobile ? "10%" : "20%",
				right: isMobile ? "10%" : "20%",
				bottom: isMobile ? "13%" : "16%",
				alignItems: "center",
				fontSize: "16px",
			}}
		>
			<IconButton
				onClick={handlePrevTestimonial}
				sx={{
					paddingRight: isMobile ? "30px" : "50px",
					color: "#f5f5f5",
					fontSize: isMobile ? "12px" : "16px",
				}}
			>
				<ArrowBackIosIcon />
				Prev
			</IconButton>
			<Button
				component={Link}
				sx={{ ...theme.buttonStyles.secondaryButton }}
				to="/client-testimonials"
			>
				{!isMobile && <span style={theme.lineStyles.line6}></span>}
				READ MORE +{!isMobile && <span style={theme.lineStyles.line5}></span>}
			</Button>

			<IconButton
				onClick={handleNextTestimonial}
				sx={{
					paddingLeft: isMobile ? "30px" : "50px",
					color: "#f5f5f5",
					fontSize: isMobile ? "12px" : "16px",
				}}
			>
				Next
				<ArrowForwardIosIcon />
			</IconButton>
		</Box>
	);
};
export default TestimonialReadButton;
