import React from "react";
import { styled, useTheme } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { YouTube, Telegram, Instagram, LinkedIn } from "@mui/icons-material";

const socialMediaIcons = [
	{
		text: "LinkedIn",
		icon: <LinkedIn aria-label="link to Annkastles linkedIn page" />,
		link: "https://www.linkedin.com/company/annkastles/",
	},
	{
		text: "YouTube",
		icon: <YouTube aria-label="link to Annkastles youtube channel" />,
		link: "https://www.youtube.com/@AnnKastles",
	},
	{
		text: "Telegram",
		icon: <Telegram aria-label="link to Annkastles telegram channel" />,
		link: "https://t.me/+oxPzq7Rmivw3YWNk",
	},
	{
		text: "Instagram",
		icon: <Instagram aria-label="link to Annkastles instagram page" />,
		link: "https://www.instagram.com/annkastles/",
	},
];

const VerticalScrollBarContainer = styled("div")({
	position: "fixed",
	top: "66%",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	gap: "10px",
	zIndex: "1",
});
const IconContainer = styled("div")({
	borderRadius: "50%",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	color: "#1B1B1B",
	backgroundColor: "rgba(216,216,216,0.6)",
	border: "1px solid rgba(216,216,216,0.6)",
	cursor: "pointer",
	transition: "all 0.4s",
	"&:hover": {
		backgroundColor: "rgba(255, 255, 255, 0.5)",
		transform: "scale3d(1.15,1.15,1.15)",
	},
});

const SocialBar = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));
	return (
		<VerticalScrollBarContainer
			sx={{ right: isDesktop ? "0.25%" : isMobile ? "1.2%" : "1.25%" }}
		>
			{socialMediaIcons.map((item) => (
				<a
					key={item.text}
					href={item.link}
					target="_blank"
					rel="noopener noreferrer"
				>
					<IconContainer
						sx={{
							width: isMobile ? "30px" : isDesktop ? "50px" : "40px",
							height: isMobile ? "30px" : isDesktop ? "50px" : "40px",
						}}
					>
						{item.icon}
					</IconContainer>
				</a>
			))}
		</VerticalScrollBarContainer>
	);
};
export default SocialBar;
