import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const Heading = ({ top, bottom, alignment }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const isMedium = useMediaQuery(theme.breakpoints.down("md"));

	const headingContainer = {
		display: "flex",
		flexDirection: "column",
		alignItems: alignment,
		textAlign: "right",
		justifyContent: "center",
		minWidth: "20dvw",
		maxWidth: "45dvw",
		paddingRight: "20px",
		boxSizing: "border-box",
	};

	return (
		<>
			{isMobile ? (
				<Typography
					variant="heading"
					lineHeight={1.2}
				>{`${top} ${bottom}`}</Typography>
			) : (
				<Box sx={{ ...headingContainer }}>
					<Typography variant="heading">{top}</Typography>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="center"
						alignItems="center"
						mt={-1.5}
					>
						<Typography
							mr={2}
							height="3px"
							bgcolor="grey"
							minWidth={isMedium ? "80px" : "160px"}
						></Typography>
						<Typography variant="heading2">{bottom}</Typography>
					</Box>
				</Box>
			)}
		</>
	);
};
export default Heading;
