import { db } from "../Config/firebase-config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const SubmitSubscriptionRequest = async (e, { inputData }) => {
	try {
		await addDoc(collection(db, "Connection Requests"), {
			...inputData,
			createdAt: serverTimestamp(),
		});
		alert("Thank you for connecting with us!");
	} catch (error) {
		console.log(error);
	}
};
export default SubmitSubscriptionRequest;
