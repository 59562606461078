import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/system";
import EllingtonVilla1 from "../Assets/Images/HeroImages/EllingtonVilla1 (1600).webp";
import EllingtonVilla2 from "../Assets/Images/HeroImages/EllingtonVilla2 (1600).webp";
import PalmJebelAli from "../Assets/Images/HeroImages/PalmJebelAli (1600).webp";

const images = [EllingtonVilla1, EllingtonVilla2, PalmJebelAli];

const Hero = () => {
	const settings = {
		arrows: false,
		dots: false,
		infinite: true,
		speed: 5,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
	};

	return (
		<Box position="relative">
			<Slider {...settings}>
				{images.map((image, index) => (
					<div key={index}>
						<img
							src={image}
							alt={`Beautiful shot of one of Annkastles featured properties`}
							style={{
								minWidth: "100%",
								maxWidth: "100%",
								maxHeight: "95dvh",
								minHeight: "55dvh",
								objectFit: "cover",
								transition: "all 2s ease-in-out",
							}}
						/>
					</div>
				))}
			</Slider>
			{/* {!isMobile && <Logo width={"26dvw"} />} */}
		</Box>
	);
};

export default Hero;
