import {
	Box,
	ImageList,
	ImageListItem,
	Button,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import PropertyCards from "./PropertyCards";
import data from "../Assets/Data/data";
import Heading from "./Heading";

const FeaturedProjects = () => {
	const theme = useTheme();
	const properties = data.slice(0, 8);
	const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Adjust breakpoint as needed
	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

	const activeListings = properties.map((project) => {
		return (
			<div key={project.id}>
				<Link to={`/active-projects/${project.id}`}>
					<ImageListItem>
						<PropertyCards
							imageUrl={project.coverImage}
							alt={project.coverImageAlt}
							title={project.title}
							price={project.price}
						/>
					</ImageListItem>
				</Link>
			</div>
		);
	});

	return (
		<Box
			id="featured-projects"
			marginTop={isMobile ? 0 : isDesktop ? -12 : -7}
			px={isMobile ? 3 : 8}
			sx={{ boxSizing: "border-box" }}
		>
			<ImageList
				component={"ul"}
				variant="masonry"
				cols={isMobile ? 1 : 3}
				gap={isMobile ? 12 : 6}
			>
				<ImageListItem
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column",
						padding: isMobile
							? "20px 20px 0px 20px"
							: isDesktop
							? "160px 40px 80px 40px"
							: "80px 40px 50px 40px",
					}}
				>
					<Heading top="FEATURED" bottom="PROJECTS" alignment={"flex-end"} />
					{isMobile && <Box pt={4}>{activeListings}</Box>}
					<Button
						component={Link}
						to="/active-projects"
						sx={{
							...theme.buttonStyles.secondaryButton,
							margin: "50px 0px 0px 0px",
						}}
					>
						{!isMobile && <span style={theme.lineStyles.line1}></span>}VIEW ALL
						PROPERTIES +
					</Button>
				</ImageListItem>
				{!isMobile && activeListings}
			</ImageList>
		</Box>
	);
};
export default FeaturedProjects;
