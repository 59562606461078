import { storage } from "../Config/firebase-config";
import { ref, getDownloadURL, listAll } from "firebase/storage";

const FetchPropertyImages = async (propertyId) => {
	const imagesRef = ref(storage, `Property Images/${propertyId}`);
	const imageURLs = [];
	try {
		const imageList = await listAll(imagesRef);
		const downloadPromises = imageList.items.map(async (item) => {
			const url = getDownloadURL(item);
			imageURLs.push(url);
		});
		await Promise.all(downloadPromises);
		return imageURLs;
	} catch (error) {
		console.log("Error fetching images: ", error);
	}
};

export default FetchPropertyImages;
