import {
	Card,
	Typography,
	CardContent,
	useTheme,
	useMediaQuery,
} from "@mui/material";

const TestimonialCard = ({ description, person }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Card
			sx={{
				background: "#1B1B1B",
				color: "#666666",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				textAlign: "justify",
				maxWidth: isMobile ? "80dvw" : "40dvw", // Adjust the width based on screen size
				padding: isMobile ? "10px 4px" : "30px 40px",
			}}
		>
			<CardContent>
				<Typography variant="body4" component="blockquote">
					"{description}"
				</Typography>
				<Typography
					variant="body4"
					component="footer"
					pt={2}
					fontWeight={"800"}
				>
					<cite title="Name of the endorser"> - {person}</cite>
				</Typography>
			</CardContent>
		</Card>
	);
};

export default TestimonialCard;
