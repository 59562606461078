import { Typography, Grid, useTheme, useMediaQuery } from "@mui/material";
import { useInView } from "react-intersection-observer";
import "../Assets/Styles/About.css";
import AbstractGrey from "../Assets/Images/BackgroundImages/AbstractGrey_1000.webp";

const AboutWhy = () => {
	const [ref, inView] = useInView({
		triggerOnce: true,
	});
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<section ref={ref} className={`fade-in ${inView ? "is-in-viewport" : ""}`}>
			<Grid container className="section-container" py={isMobile ? 6 : 12}>
				<Grid item xs={12} md={6}>
					<img
						className="section-image"
						src={AbstractGrey}
						alt="geometrical building"
					/>
				</Grid>
				<Grid item xs={12} md={6} pl={isMobile ? 0 : 4}>
					<Typography variant="heading1">WHY ANNKASTLES</Typography>
					<Typography
						variant="body2"
						textAlign={"justify"}
						pt={isMobile ? 0 : 5}
					>
						All Roads lead to Dubai! In recent years, Dubai with its refined
						taste has emerged as a magnet for affluent-class property buyers
						across the globe. If you are not familiar with Dubai, UAE, you can
						leave it to us to handhold you to familiarise with the place, and
						eco-system and share what it has got to offer you. We offer
						personalized services and an unparalleled understanding of the
						luxury real estate market in Dubai. We understand the importance of
						discretion and provide exclusive access to off-market properties,
						ensuring the utmost privacy and confidentiality.
					</Typography>
				</Grid>
			</Grid>
		</section>
	);
};

export default AboutWhy;
